import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Airdrop} from "../model/firestore/airdrop";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AirdropService} from "../services/airdrop.service";
import firebase from "firebase/compat/app";
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-edit-airdrop-dialog',
  templateUrl: './edit-airdrop-dialog.component.html',
  styleUrls: ['./edit-airdrop-dialog.component.css']
})
export class EditAirdropDialogComponent implements OnInit, AfterViewInit {

  thumbnailPath = 'https://i.stack.imgur.com/mwFzF.png'
  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;

  listOfBadges: string[] = ['Email', 'Phone', 'KYC', 'Social', 'Swap', 'Bridge', 'Deposit', 'Snapshot', 'Quiz'];

  form: FormGroup;

  airdrop: Airdrop;

  constructor(
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<EditAirdropDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)airdrop: Airdrop,
    private airdropsService: AirdropService
  ){
    this.airdrop = airdrop;

    this.form = this.fb.group({
      title: [airdrop.title, {
        validators: [Validators.required,
        Validators.minLength(5),
        Validators.maxLength(60)]
      }],
      description: [airdrop.description, {
        validators: [
          Validators.required,
          Validators.minLength(100)
        ]
      }],
      live: [airdrop.live],
      iconUrl: [airdrop.iconUrl, Validators.required],
      url: [airdrop.url, {
        validators: [Validators.required,
          Validators.pattern(this.urlRegex)]
      }],
      category: [airdrop.categories[0]],
      action: [airdrop.action],
      amount: [airdrop.amount],
      currency: [airdrop.currency],
      startDate: [airdrop.startDate!=null ? airdrop.startDate.toDate() : null],
      endDate: [airdrop.endDate!=null ? airdrop.endDate.toDate() : null],
      badges: [airdrop.badges]
    });
  }

  close(){
    this.dialogRef.close();
  }

  save(){
    const changes = this.form.value;

    const newAirdrop: Partial<Airdrop> = {
      title: changes.title,
      url: changes.url,
      description: changes.description,
      iconUrl: changes.iconUrl,
      categories: [changes.category],
      live: changes.live,
      currency: changes.currency,
      amount: changes.amount,
      action: changes.action,
      badges: changes.badges
    }

    if(changes.startDate!=null){
      newAirdrop.startDate = changes.startDate;
    }else{
      const dummyDate = new Date('1970/01/01 0:00:00');
      newAirdrop.startDate = Timestamp.fromDate(new Date(dummyDate.getTime()))
    }

    if(changes.endDate!=null){
      newAirdrop.endDate = changes.endDate;
    }else{
      const dummyDate = new Date('1970/01/01 0:00:00');
      newAirdrop.endDate = Timestamp.fromDate(new Date(dummyDate.getTime()))
    }

    this.airdropsService.updateAirdrop(this.airdrop.id, newAirdrop)
      .subscribe(()=>{
        this.dialogRef.close(newAirdrop);
        window.location.reload();
      })
  }

  ngOnInit(): void {
  }

  onImageError(event) {
    event.target.src = this.thumbnailPath;
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
}
