
import { Meta, Title } from '@angular/platform-browser';
import { AfterViewInit, ChangeDetectorRef,Component, Injectable,OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Observable } from 'rxjs';
import { Airdrop } from '../model/firestore/airdrop';
import { AirdropService } from '../services/airdrop.service';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import { UserService } from '../services/user.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UIStateService {
  pageSelectedTab = 0;
  constructor() {}
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {

  @ViewChild('tabs')
  tabs: MatTabGroup;

  filteredAirdrops$: Observable<Airdrop[]>;

  giveawaysAirdrops$: Observable<Airdrop[]>;

  bountiesAirdrops$: Observable<Airdrop[]>;

  defiAirdrops$: Observable<Airdrop[]>;

  quizAirdrops: Observable<Airdrop[]>;

  snapshotAirdrops$: Observable<Airdrop[]>;

  shouldShow: Boolean = true;

  showSpinner: Boolean = true;

  selectedIndex = 0;
  selectedTab = ' ';

  constructor(
    private cdr: ChangeDetectorRef,
    private airdropService: AirdropService,
    private tabsStateService: UIStateService,
    public user: UserService,
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta
  ) {
  }

  ngOnInit(): void {
    this.user.isLoggedIn()
  .then((loggedIn) => {
    if (loggedIn) {
      this.loadPageContent();
    } else {
      this.user.signInAnonymously()
        .then(() => {
          this.loadPageContent();
        })
        .catch(error => {
          console.log('Error signing in anonymously', error);
        });
    }
  })
  .catch(error => {
    console.log('Error checking if user is logged in', error);
  });

    // this.afAuth.authState.subscribe(user => {
    //   if (user) {
    //     // User is already signed in, load page content
    //     this.loadPageContet();
    //   } else {
    //     // User is not signed in, sign in anonymously
    //     this.afAuth.signInAnonymously()
    //       .then(() => {
    //         // User is signed in anonymously, load page content
    //         this.loadPageContet();
    //       })
    //       .catch(error => {
    //         console.log('Error signing in anonymously:', error);
    //       });
    //   }
    // });
  }



  ngAfterViewInit(): void {
    this.selectedTab = this.tabs._tabs.get(this.selectedIndex).textLabel;
    this.title.setTitle("Latest Crypto Airdrops - " + this.selectedTab)
    this.meta.updateTag({name: 'author', content: "Latest Crypto Airdrops - " + this.selectedTab})
    this.cdr.detectChanges();
  }



  tabChanged(event: MatTabChangeEvent) {
    this.tabsStateService.pageSelectedTab = event.index;
    this.selectedTab = event.tab.textLabel;
    this.title.setTitle("Latest Crypto Airdrops - " + this.selectedTab)
    this.meta.updateTag({name: 'author', content: "Latest Crypto Airdrops - " + this.selectedTab})
  }

  async loadPageContent(){
    this.showSpinner = false;

    this.route.params.subscribe(params => {
      if (params.searchTerm){
        this.shouldShow = false;
        this.filteredAirdrops$ = this.airdropService.searchForAirdrop(params.searchTerm)
      }else{
        this.shouldShow = true;
        this.selectedIndex = this.tabsStateService.pageSelectedTab;
        this.reloadAirdrops();
      }
    })
  }


  reloadAirdrops() {
    this.giveawaysAirdrops$ = this.airdropService.loadAirdropsByCategory('GIVEAWAY');

    this.bountiesAirdrops$ = this.airdropService.loadAirdropsByCategory('BOUNTY');

    this.snapshotAirdrops$ = this.airdropService.loadAirdropsByCategory('SNAPSHOT');

    this.defiAirdrops$ = this.airdropService.loadAirdropsByCategory('DEFI');

    this.quizAirdrops = this.airdropService.loadAirdropsByCategory('QUIZ');
  }

}