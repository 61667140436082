<mat-card *ngFor="let airdrop of airdrops" class="airdrop-card mat-elevation-z10">

    <div class="main-div">

      <div class="header">

        <mat-card-header>

          <mat-card-title>{{airdrop.title}}</mat-card-title>

          <ng-container *ngIf="(user.roles$ | async) as roles">

            <ng-container *ngIf="roles.admin">

          <button id="top-up-btn"  mat-mini-fab color="warn"
          (click)="topUpAirdrop(airdrop)">
            <mat-icon>keyboard_arrow_up</mat-icon>
          </button>

        </ng-container>

      </ng-container>

        </mat-card-header>

      </div>



      <div class="airdrop-action-container">
        <mat-icon>code</mat-icon>
        <h3>{{airdrop.action}}</h3>
      </div>

      <img  mat-card-image [src]="airdrop.iconUrl" alt="{{airdrop.title}}"
           class="image-responsive mat-elevation-z5">

      <div class="badges-container">

        <div *ngIf="airdrop.badges.includes('KYC')" class="badge">

          <p>KYC</p>

          <mat-icon>person</mat-icon>

        </div>

        <div *ngIf="airdrop.badges.includes('Email')" class="badge">

          <p>m@il</p>

          <mat-icon>email</mat-icon>

        </div>

        <div *ngIf="airdrop.badges.includes('Phone')" class="badge">

          <p>Phone</p>

          <mat-icon>phone</mat-icon>

        </div>

        <div *ngIf="airdrop.badges.includes('Social')" class="badge">

          <p>Social</p>

          <mat-icon>people</mat-icon>

        </div>

        <div *ngIf="airdrop.badges.includes('Swap')" class="badge">

          <p>Swap</p>

          <mat-icon>swap_calls</mat-icon>

        </div>

        <div *ngIf="airdrop.badges.includes('Bridge')" class="badge">

          <p>Bridge</p>

          <mat-icon>looks</mat-icon>

        </div>

        <div *ngIf="airdrop.badges.includes('Deposit')" class="badge">

          <p>Top up</p>

          <mat-icon>money</mat-icon>

        </div>

        <div *ngIf="airdrop.badges.includes('Snapshot')" class="badge">

          <p>Snap</p>

          <mat-icon>camera</mat-icon>

        </div>

        <div *ngIf="airdrop.badges.includes('Quiz')" class="badge">

          <p>Quiz</p>

          <mat-icon>quiz</mat-icon>

        </div>

      </div>

      <mat-card-content class="description">
        <p>{{airdrop.description}}</p>
      </mat-card-content>

      <mat-card-actions class="airdrop-actions">

      <button mat-button class="mat-raised-button mat-primary" (click)="openAirdropInNewWindow('/airdrops', airdrop.title)">
        {{ getAmount(airdrop) }}
      </button>




        <ng-container *ngIf="(user.roles$ | async) as roles">

          <ng-container *ngIf="roles.admin">

            <button mat-button class="mat-raised-button mat-accent"
            (click)="editAirdrop(airdrop)">
              EDIT
            </button>
            <button mat-mini-fab color="warn" (click)="onDeleteAirdrop(airdrop)">
              <mat-icon>delete</mat-icon>
            </button>

          </ng-container>

        </ng-container>

      </mat-card-actions>

    </div>

  </mat-card>
