<h2 mat-dialog-title>Edit Airdrop</h2>

<mat-dialog-content [formGroup]="form">

  <div class="live-toggle">

    <mat-slide-toggle   color="primary" formControlName="live">
      Airdrop in live?
    </mat-slide-toggle>

  </div>

  <mat-form-field appearance="outline">

    <mat-label>title</mat-label>

    <input placeholder="Airdrop title" matInput formControlName="title" #title>

    <mat-hint align="end">
      {{title.value.length}} / 60
    </mat-hint>

  </mat-form-field>

  <img class="airdrop-icon mat-elevation-z3"
       [src]="imagePreview.value" (error)="onImageError($event)" alt="">

  <mat-form-field appearance="outline">

    <mat-label>icon url</mat-label>

    <input  placeholder="Pass icon url here" matInput formControlName="iconUrl" #imagePreview>

  </mat-form-field>

  <mat-form-field appearance="outline">

    <mat-select placeholder="Select category" formControlName="category">
      <mat-option value="GIVEAWAY">GIVEAWAY</mat-option>
      <mat-option value="BOUNTY">BOUNTY</mat-option>
      <mat-option value="DEFI">DEFI</mat-option>
      <mat-option value="QUIZ">QUIZ</mat-option>
      <mat-option value="SNAPSHOT">SNAPSHOT</mat-option>
    </mat-select>

  </mat-form-field>

  <mat-form-field appearance="fill">

    <mat-label>description</mat-label>

    <textarea cdkTextareaAutosize matInput placeholder="Airdrop short description"
              formControlName="description" #description></textarea>

    <mat-hint align="end">
      {{description.value.length}} / 100
    </mat-hint>

  </mat-form-field>

  <mat-form-field appearance="fill">

    <input placeholder="Airdrop url or ref link" matInput formControlName="url">

    <mat-label>url</mat-label>


  </mat-form-field>

  <mat-form-field>

    <mat-label>Airdrop validity period</mat-label>

    <mat-date-range-input [rangePicker]="validityPicker">

      <input matStartDate placeholder="Start date" formControlName="startDate">

      <input matEndDate placeholder="End date" formControlName="endDate">

    </mat-date-range-input>

    <mat-error *ngIf="form.errors?.promoPeriod && form.dirty">
      The start date must be before the end date.</mat-error>

    <mat-datepicker-toggle matSuffix [for]="validityPicker"></mat-datepicker-toggle>

    <mat-date-range-picker #validityPicker></mat-date-range-picker>

  </mat-form-field>

  <mat-form-field appearance="fill">

    <input placeholder="Action to get reward" matInput formControlName="action">

    <mat-label>action</mat-label>

  </mat-form-field>

  <mat-form-field appearance="fill">

    <input placeholder="Currency paid out (crypto or usd)" matInput formControlName="currency">

    <mat-label>currency</mat-label>

  </mat-form-field>

  <mat-form-field appearance="outline">

    <input type="number" matInput placeholder="Price" formControlName="amount">

    <mat-label>amount</mat-label>

  </mat-form-field>

  <mat-selection-list formControlName="badges">
    <mat-list-option value="{{badge}}" *ngFor="let badge of listOfBadges">
      {{badge}}
    </mat-list-option>
  </mat-selection-list>

</mat-dialog-content>

<mat-dialog-actions>

  <button mat-raised-button (click)="close()">
    Close
  </button>

  <button mat-raised-button color="primary" (click)="save()">
    Save
  </button>

</mat-dialog-actions>
