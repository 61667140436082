import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-airdrop-step-four',
  templateUrl: './airdrop-step-four.component.html',
  styleUrls: ['./airdrop-step-four.component.css']
})
export class AirdropStepFourComponent implements OnInit {


  form = this.fb.group({
    steps: this.fb.array([])
  })

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  get steps(){
    return this.form.controls['steps'] as FormArray;
  }

  addSteps(){
    const stepForm = this.fb.group({
      description: ['', Validators.required],
      url: ['']
    })

    this.steps.push(stepForm);
  }

  deleteStep(stepIndex: number){
    this.steps.removeAt(stepIndex)
  }

}
