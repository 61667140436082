<div class="countdown">
  <div class="countdown-item">
    <span class="countdown-value">{{ countdown?.days }}</span>
    <span class="countdown-label">Days</span>
  </div>
  <div class="countdown-item">
    <span class="countdown-value">{{ countdown?.hours }}</span>
    <span class="countdown-label">Hours</span>
  </div>
  <div class="countdown-item">
    <span class="countdown-value">{{ countdown?.minutes }}</span>
    <span class="countdown-label">Minutes</span>
  </div>
  <div class="countdown-item">
    <span class="countdown-value">{{ countdown?.seconds }}</span>
    <span class="countdown-label">Seconds</span>
  </div>
</div>
