import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {throwError} from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Airdrop } from '../model/firestore/airdrop';
import { AirdropService } from '../services/airdrop.service';
import { UserService } from '../services/user.service';
import {EditAirdropDialogComponent} from "../edit-airdrop-dialog/edit-airdrop-dialog.component";
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-airdrop-card-list',
  templateUrl: './airdrop-card-list.component.html',
  styleUrls: ['./airdrop-card-list.component.css']
})
export class AirdropCardListComponent implements OnInit {

  @Input()
  airdrops: Airdrop[];

  @Output()
  airdropEdited = new EventEmitter();

  @Output()
  airdropDeleted = new EventEmitter<Airdrop>();

  constructor(
    private router: Router,
    private location: Location,
    private dialog: MatDialog,
    private airdropService: AirdropService,
    public user: UserService
  ) { }

  ngOnInit(): void {
  }

  onDeleteAirdrop(airdrop: Airdrop) {
    this.airdropService.deleteAirdropAndLikes(airdrop.id)
      .pipe(
        tap(() => {
          console.log('Airdrop deleted!', airdrop);
          this.airdropDeleted.emit(airdrop);
          if (airdrop.imageUrl!=''){
            this.airdropService.deleteFromStorage(airdrop.imageUrl)
              .catch(err => {
                console.log(err)
              });
          }

        }),
        catchError(err => {
          console.log(err);
          alert('Could not delete airdrop');
          return throwError(err);
        })
      )
      .subscribe();
  }

  openAirdropInNewWindow(route: string, title: string){
    const fullPath = route + '/' + title;
    // const fullUrl = route + '/' + encodeURIComponent(url);
    // const preparedUrl = this.location.prepareExternalUrl(fullUrl);
    window.open(fullPath, '_blank');
  }
  

  // openCityInNewWindow(city) {
  //   // Converts the route into a string that can be used
  //   // with the window.open() function
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree([`/custompage/${city.id}`])
  //   );

  //   window.open(url, '_blank');
  // }


  topUpAirdrop(airdrop: Airdrop){
    this.airdropService.topUpAirdrop(airdrop, airdrop.id)
    .pipe(
      tap(airdrop => {
        console.log("TopUp airdorp seqNo", airdrop)
        this.airdropEdited.emit();
      })
    )
    .subscribe();
  }

  editAirdrop(airdrop: Airdrop) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "80%";

    dialogConfig.data = airdrop;

    this.dialog.open(EditAirdropDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(val => {
        if (val){
          this.airdropEdited.emit();
        }
      });

  }

  getAmount(airdrop: Airdrop) {
    if (airdrop.amount != 0 && airdrop.currency != "nil"){
      return 'Claim ' + airdrop.amount + " " + airdrop.currency
    }else {
      return 'Airdrop Details'
    }
  }

}
