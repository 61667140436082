import { Component, OnInit } from '@angular/core';
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Airdrop} from "../model/firestore/airdrop";
import firebase from "firebase/compat/app";
import Timestamp = firebase.firestore.Timestamp;
import {Router} from "@angular/router";
import {AirdropService} from "../services/airdrop.service";
import {catchError, tap} from "rxjs/operators";
import {throwError} from "rxjs";

@Component({
  selector: 'app-create-airdrop',
  templateUrl: './create-airdrop.component.html',
  styleUrls: ['./create-airdrop.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
    }
  ]
})
export class CreateAirdropComponent implements OnInit {

  airdropId: string;

  constructor(private afs: AngularFirestore,
              private router: Router,
              private airdropService: AirdropService) { }

  ngOnInit(): void {
    this.airdropId = this.afs.createId();
  }

  submit(step1: any, step2: any, step3: any, step4: any) {

    const newAirdrop: Partial<Airdrop> = {
      title: step1.title,
      url: step1.url,
      description: step1.description,
      iconUrl: step3.iconUrl,
      imageUrl: step3.imageUrl,
      categories: [step1.category],
      live: step1.live,
      currency: step1.currency,
      amount: step1.amount,
      action: step1.action,
      badges: step2.badges,
      steps: step4.steps
    }

    newAirdrop.likesCount = 0;

    // const dummyDate = new Date('March 06, 1970 00:00:00');

    if (step1.startDate != null){
      newAirdrop.startDate = Timestamp.fromDate(new Date(step1.startDate));
    }else{
      const dummyDate = new Date('1970/01/01 0:00:00');
      newAirdrop.startDate = Timestamp.fromDate(new Date(dummyDate.getTime()))
    }

    if (step1.endDate != null){
      newAirdrop.endDate = Timestamp.fromDate(new Date(step1.endDate));
    }else{
      const dummyDate = new Date('1970/01/01 0:00:00');
      newAirdrop.endDate = Timestamp.fromDate(new Date(dummyDate.getTime()))
    }


      this.airdropService.createAirdrop(newAirdrop, this.airdropId)
        .pipe(
          tap(airdrop => {
            console.log('Created new airdrop: ', airdrop)
            localStorage.clear();
            this.router.navigateByUrl('/airdrops').then(err => {
              console.log(err);
              alert(err);
            });
          }),
          catchError(err => {
            alert('Could not create the airdrop');
            return throwError(err);
          })
        )
        .subscribe();

  }

  clearCache() {
    localStorage.clear();
    window.location.reload();
  }
}
