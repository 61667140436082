<div>

    <div id="spinner-container" *ngIf="!isLoggedIn">
      <mat-spinner mode="indeterminate"></mat-spinner>
    </div>

    <div class="airdrop" *ngIf="isLoggedIn">

      <h2>{{airdrop?.title}}</h2>

      <img *ngIf="airdrop.imageUrl" [src]="airdrop?.imageUrl" class="airdrop-thumbnail mat-elevation-z8" alt="">

      <p class="airdrop-description" *ngFor="let paragraph of airdrop?.description.split('\n')">
        <span class="first-word">{{ paragraph.split(' ')[0] }} </span>{{ paragraph.slice(paragraph.indexOf(' ') + 1) }}
      </p>

      <ng-container *ngIf="(user.roles$ | async) as roles">

        <ng-container *ngIf="roles.admin">

          <button mat-button class="mat-raised-button mat-accent"
                  (click)="editAirdrop(airdrop)">
            Edit Steps
          </button>

        </ng-container>

      </ng-container>

      <mat-table class="steps-table mat-elevation-z8" [dataSource]="steps">

        <ng-container matColumnDef="seqNo">

          <mat-header-cell *matHeaderCellDef>#</mat-header-cell>

          <mat-cell class="position-cell" *matCellDef="let _; let i = index">{{i + 1}}.</mat-cell>

        </ng-container>

        <ng-container matColumnDef="step">

          <mat-header-cell *matHeaderCellDef>Step</mat-header-cell>

          <mat-cell class="step-cell"
                    *matCellDef="let step">{{step.description}}</mat-cell>

        </ng-container>

        <ng-container matColumnDef="action">

          <mat-header-cell  *matHeaderCellDef ></mat-header-cell>

          <mat-cell class="action-cell" *matCellDef="let step">

            <mat-icon *ngIf="step.url" class="url-button"
                      (click)="openUrl(step.url)">link</mat-icon>
          </mat-cell>

        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

      </mat-table>

      <button mat-button class="mat-raised-button mat-primary mat-elevation-z5" (click)="openUrl(airdrop.url)">Claim Airdrop</button>

      <div class="countdown-container" *ngIf="checkEndDate(airdrop.endDate)">
        <div class="countdown-header">

   <mat-icon>alarm</mat-icon>

   <h1>Airdrop countdown</h1>

        </div>

        <app-countdown-timer [targetDate]="convertFromTimestamp(airdrop.endDate)"></app-countdown-timer>

      </div>

    </div>

   <app-my-social-links *ngIf="isLoggedIn"></app-my-social-links>

    <div class="share-btn-container" *ngIf="isLoggedIn">

      <a (click)="share('facebook')">
        <i class="social-icon fab fa-facebook"></i>
      </a>

      <a (click)="share('reddit')">
        <i class="social-icon fab fa-reddit"></i>
      </a>

      <a (click)="share('telegram')">
        <i class="social-icon fab fa-telegram"></i>
      </a>

      <a (click)="share('whatsapp')">
        <i class="social-icon fab fa-whatsapp"></i>
      </a>

      <a (click)="share('twitter')">
        <i class="social-icon fab fa-twitter"></i>
      </a>

    </div>

</div>






