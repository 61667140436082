import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Airdrop } from "../model/firestore/airdrop";
import { AirdropService } from "./airdrop.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class AirdropResolver implements Resolve<Airdrop> {

  constructor(
    private airdropService: AirdropService,
    private userService: UserService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Airdrop> {
    const airdropTitle = route.paramMap.get('airdropTitle');

    return new Observable<Airdrop>(observer => {
      this.userService.isLoggedIn().then(loggedIn => {
        if (loggedIn) {
          this.airdropService.findAirdropByTitle(airdropTitle).subscribe(
            airdrop => {
              observer.next(airdrop);
              observer.complete();
            },
            error => {
              observer.error(error);
              observer.complete();
            }
          );
        } else {
          this.userService.signInAnonymously().then(() => {
            this.airdropService.findAirdropByTitle(airdropTitle).subscribe(
              airdrop => {
                observer.next(airdrop);
                observer.complete();
              },
              error => {
                observer.error(error);
                observer.complete();
              }
            );
          }).catch(error => {
            observer.error(error);
            observer.complete();
          });
        }
      }).catch(error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
