import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {last, Observable, throwError} from "rxjs";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {catchError, concatMap, filter, tap} from "rxjs/operators";

@Component({
  selector: 'app-airdrop-step-three',
  templateUrl: './airdrop-step-three.component.html',
  styleUrls: ['./airdrop-step-three.component.css']
})
export class AirdropStepThreeComponent implements OnInit {

  @Input()
  airdropId: string;

  thumbnailPath = 'https://i.stack.imgur.com/mwFzF.png'

  headerImage: string = '';
  percentageChanges$: Observable<number>;

  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;

  public preview = 'src/assets/preview.jpg'

  form = this.fb.group({
    iconUrl: ['', {
      validators: [Validators.required,
        Validators.pattern(this.urlRegex)]
    }],
    imageUrl:['']
  })

  constructor(private fb: FormBuilder,
              private storage: AngularFireStorage) { }

  ngOnInit(): void {
    const draft = localStorage.getItem("STEP_3");

    if (draft) {
      this.form.setValue(JSON.parse(draft));
    }

    this.form.valueChanges
      .pipe(
        filter(() => this.form.valid)
      )
      .subscribe( val => localStorage.setItem("STEP_3", JSON.stringify(val)));
  }

  uploadThumbnail(event: any) {
    const file = event.target.files[0];

    const filePath = `airdrops/${this.airdropId}/${file.name}`;

    const task = this.storage.upload(filePath, file, {
      cacheControl: 'max-age=2592000,public'
    });

    this.percentageChanges$ = task.percentageChanges();

    task.snapshotChanges()
      .pipe(
        last(),
        concatMap(() => this.storage.ref(filePath).getDownloadURL()),
        tap(url => this.headerImage = url),
        catchError(err => {
          console.log(err);
          alert('Could not create thumbnail');
          return throwError(err);
        })
      )
      .subscribe(()=>{
        if(this.headerImage!=''){
          this.form.controls['imageUrl'].setValue(this.headerImage);
        }
      });


  }

  onImageError(event) {
    event.target.src = this.thumbnailPath;
  }
}
