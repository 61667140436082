import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatCardModule} from "@angular/material/card";
import {MatTabsModule} from "@angular/material/tabs";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatListModule} from "@angular/material/list";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatInputModule} from "@angular/material/input";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSelectModule} from "@angular/material/select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatTableModule} from "@angular/material/table";
import {MatStepperModule} from "@angular/material/stepper";
import {ToastrModule} from "ngx-toastr";


import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import {AngularFirestoreModule, USE_EMULATOR as USE_FIRESTORE_EMULATOR} from "@angular/fire/compat/firestore";
import {AngularFireStorageModule} from "@angular/fire/compat/storage";
import {AngularFireAuthModule, USE_EMULATOR as USE_AUTH_EMULATOR} from "@angular/fire/compat/auth";
import {AngularFireFunctionsModule, USE_EMULATOR as USE_FUNCTIONS_EMULATOR} from "@angular/fire/compat/functions";



import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { AirdropComponent } from './airdrop/airdrop.component';
import { AirdropCardListComponent } from './airdrop-card-list/airdrop-card-list.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { LoginComponent } from './login/login.component';
import { EditAirdropDialogComponent } from './edit-airdrop-dialog/edit-airdrop-dialog.component';
import { environment } from 'src/environments/environment.prod';
import { CreateAirdropComponent } from './create-airdrop/create-airdrop.component';
import { AirdropBlueprintsComponent } from './airdrop-blueprints/airdrop-blueprints.component';
import { AirdropStepOneComponent } from './create-airdrop/airdrop-step-1/airdrop-step-one.component';
import { AirdropStepTwoComponent } from './create-airdrop/airdrop-step-2/airdrop-step-two.component';
import { AirdropStepThreeComponent } from './create-airdrop/airdrop-step-3/airdrop-step-three.component';
import { AirdropStepFourComponent } from './create-airdrop/airdrop-step-4/airdrop-step-four.component';
import {AuthInterceptor} from './services/auth.interceptor';
import { EditAirdropStepsComponent } from './edit-airdrop-steps/edit-airdrop-steps.component';
import { TopCoinsHeaderComponent } from './top-coins-header/top-coins-header.component';
import { MySocialLinksComponent } from './my-social-links/my-social-links.component';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    AirdropComponent,
    AirdropCardListComponent,
    CreateUserComponent,
    LoginComponent,
    EditAirdropDialogComponent,
    CreateAirdropComponent,
    AirdropBlueprintsComponent,
    AirdropStepOneComponent,
    AirdropStepTwoComponent,
    AirdropStepThreeComponent,
    AirdropStepFourComponent,
    EditAirdropStepsComponent,
    TopCoinsHeaderComponent,
    MySocialLinksComponent,
    SearchbarComponent,
    CountdownTimerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatListModule,
    MatToolbarModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSelectModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9099] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
