import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import firebase from "firebase/compat/app";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserRoles } from "../model/firestore/user-roles";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  isLoggedIn$: Observable<boolean>;
  isLoggedOut$: Observable<boolean>;
  userUID$: Observable<string>;
  pictureUrl$: Observable<string>;
  roles$: Observable<UserRoles>;

  constructor(private afAuth: AngularFireAuth, private router: Router) {
    this.isLoggedIn$ = new Observable<boolean>((observer) => {
      afAuth.authState.subscribe((user) => {
        observer.next(!!user);
      });
    });

    this.isLoggedOut$ = this.isLoggedIn$.pipe(map(loggedIn => !loggedIn));

    this.userUID$ = new Observable<string>((observer) => {
      afAuth.authState.subscribe((user) => {
        observer.next(user ? user.uid : null);
      });
    });

    this.pictureUrl$ = new Observable<string>((observer) => {
      afAuth.authState.subscribe((user) => {
        observer.next(user ? user.photoURL : null);
      });
    });

    this.roles$ = new Observable<UserRoles>((observer) => {
      this.afAuth.idTokenResult.subscribe((token) => {
        const claims = (token?.claims as any) ?? { admin: false };
        observer.next(claims);
      });
    });
  }

  isLoggedIn(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.afAuth.authState.subscribe((user: firebase.User | null) => {
        if (user) {
          resolve(true);
        } else {
          this.afAuth.signInAnonymously()
            .then(() => {
              resolve(false);
            })
            .catch((error: firebase.FirebaseError) => {
              console.log('Error signing in anonymously:', error);
              reject(error);
            });
        }
      });
    });
  }


  signInAnonymously(): Promise<firebase.auth.UserCredential> {
    return this.afAuth.signInAnonymously();
  }

  logout() {
    this.afAuth.signOut();
    this.router.navigateByUrl('/login');
  }
}
