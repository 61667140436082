import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {airdropTitleValidator} from "../../validators/airdrop-title.validator";
import {AirdropService} from "../../services/airdrop.service";
import {createAirdropRangeValidator} from "../../validators/date-range.validator";
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-airdrop-step-one',
  templateUrl: './airdrop-step-one.component.html',
  styleUrls: ['./airdrop-step-one.component.css']
})
export class AirdropStepOneComponent implements OnInit {

  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;

  form = this.fb.group({
    live: [false],
    title: ['', {
      validators: [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(60)
      ],
      asyncValidators: [airdropTitleValidator(this.airdrops)],
      updateOn: 'blur'
    }],
    url: ['', {
     validators: [
       Validators.required,
       Validators.pattern(this.urlRegex)]
    }],
    category: ['GIVEAWAY', Validators.required],
    description: ['', {
      validators: [Validators.required, Validators.minLength(100)]
    }],
    startDate: [null],
    endDate: [null],
    action: ['', Validators.required],
    amount: [0, Validators.required],
    currency: ['nil', Validators.required]
  },{
    validators: [createAirdropRangeValidator]
  })

  constructor(private fb: FormBuilder,
              private airdrops: AirdropService) { }

  ngOnInit(): void {
    const draft = localStorage.getItem("STEP_1");

    if (draft) {
      this.form.setValue(JSON.parse(draft));
    }

    this.form.valueChanges
      .pipe(
        filter(() => this.form.valid)
      )
      .subscribe( val => localStorage.setItem("STEP_1", JSON.stringify(val)));
  }

  get airdropTitle(){
    return this.form.controls['title'];
  }



}
