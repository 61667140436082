<h3>Add Steps</h3>

<div class="add-steps-form" [formGroup]="form">

    <ng-container formArrayName="steps">

        <ng-container *ngFor="let stepForm of steps.controls; let i = index">

            <div class="step-form-row" [formGroup]="stepForm">

                <p>{{i+1}}.</p>

                <mat-form-field appearance="fill">

                   <input matInput
                    formControlName="description"
                    placeholder="step description">

                </mat-form-field>

                <mat-form-field appearance="fill">
                    <input matInput
                     formControlName="url"
                     placeholder="url if needed">

                 </mat-form-field>

              <mat-icon color="warn" class="delete-btn" (click)="deleteStep(i)"
              >delete_forever</mat-icon>


            </div>

        </ng-container>

    </ng-container>

  <button mat-mini-fab (click)="addSteps()">
    <mat-icon class="add-step-btn">add</mat-icon>
  </button>

</div>
