<app-top-coins-header></app-top-coins-header>

  <div class="airdrops-panel">

    <div class="header">

      <h2 id="header_name" class="title">{{selectedTab}}</h2>

      <ng-container *ngIf="(user.roles$ | async) as roles">

        <ng-container *ngIf="roles.admin">

            <button class="add-airdrop-button" mat-mini-fab color="accent"  routerLink="/create-airdrop">
              <mat-icon >add</mat-icon>
            </button>

            <button mat-mini-fab color="accent"  routerLink="/blueprints">
              <mat-icon >history</mat-icon>
            </button>


        </ng-container>

      </ng-container>

    </div>

    <app-searchbar></app-searchbar>

    <div>

      <app-airdrop-card-list [airdrops]="filteredAirdrops$ | async"></app-airdrop-card-list>

    </div>

    <div id="spinner-container" *ngIf="showSpinner">
      <mat-spinner mode="indeterminate"></mat-spinner>
    </div>

    <div id="no-results" *ngIf="(filteredAirdrops$ | async)?.length === 0">
      <p>Sorry, no results were found for your search. Please try a different search term.</p>
    </div>


    <mat-tab-group *ngIf="user.isLoggedIn$" [ngClass]="{show: shouldShow, hide: !shouldShow}"  #tabs   [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChanged($event)" >

        <mat-tab label="Giveaway">

          <app-airdrop-card-list [airdrops]="giveawaysAirdrops$ | async"
          (airdropEdited)="reloadAirdrops()"
          (airdropDeleted)="reloadAirdrops()"></app-airdrop-card-list>

        </mat-tab>

        <mat-tab label="Bounty">

            <app-airdrop-card-list [airdrops]="bountiesAirdrops$ | async"
            (airdropEdited)="reloadAirdrops()"
            (airdropDeleted)="reloadAirdrops()"></app-airdrop-card-list>


        </mat-tab>

        <mat-tab label="DeFi">

            <app-airdrop-card-list [airdrops]="defiAirdrops$ | async"
            (airdropEdited)="reloadAirdrops()"
            (airdropDeleted)="reloadAirdrops()"></app-airdrop-card-list>

        </mat-tab>

        <mat-tab label="Quiz">

            <app-airdrop-card-list [airdrops]="quizAirdrops | async"
            (airdropEdited)="reloadAirdrops()"
            (airdropDeleted)="reloadAirdrops()"></app-airdrop-card-list>

        </mat-tab>

        <mat-tab label="Snapshot">

          <app-airdrop-card-list [airdrops]="snapshotAirdrops$ | async">
            (airdropEdited)="reloadAirdrops()"
            (airdropDeleted)="reloadAirdrops()"></app-airdrop-card-list>

        </mat-tab>

      </mat-tab-group>

      <app-my-social-links></app-my-social-links>

    </div>

