import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';


@Component({
  selector: 'app-airdrop-step-two',
  templateUrl: './airdrop-step-two.component.html',
  styleUrls: ['./airdrop-step-two.component.css']
})
export class AirdropStepTwoComponent implements OnInit {

  listOfBadges: string[] = ['Email', 'Phone', 'KYC', 'Social', 'Swap', 'Bridge', 'Deposit', 'Snapshot', 'Quiz'];

  form = this.fb.group({
    badges: new FormControl([])
  });

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
  }

}
