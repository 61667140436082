import {FormGroup, ValidatorFn, Validators} from '@angular/forms';


export function createAirdropRangeValidator(): ValidatorFn {
  return (form: FormGroup): Validators | null => {

    const start: Date = form.get('startAt').value;

    const end: Date = form.get('endAt').value;

    if (start && end) {
      const isRangeValid = (end.getTime() - start.getTime() > 0);

      return isRangeValid ? null : {promoPeriod: true};
    }

    return null;
  };
}
