import { Component, OnInit } from '@angular/core';
import {Coin} from "../model/coingecko/coin";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-top-coins-header',
  templateUrl: './top-coins-header.component.html',
  styleUrls: ['./top-coins-header.component.css']
})
export class TopCoinsHeaderComponent implements OnInit {

  api: string =
    'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=20&page=1&sparkline=false';
  coins: Coin[] = [];

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get<Coin[]>(this.api).subscribe(
      (res) => {
        this.coins = res;
      },
      (err) => console.error(err)
    );


  }

}
