<div [formGroup]="form">

<img class="airdrop-icon mat-elevation-z3"
     [src]="imagePreview.value" (error)="onImageError($event)" alt="">

  <mat-form-field appearance="outline">

    <mat-label>icon url</mat-label>

    <input  placeholder="Pass icon url here" matInput formControlName="iconUrl" #imagePreview>

  </mat-form-field>

        <div class="airdrop-image-upload">

          <img class="airdrop-thumbnail mat-elevation-z3"
          [src]="headerImage"
          *ngIf="headerImage" alt="">

          <h4>Header image</h4>

          <input type="file" (change)="uploadThumbnail($event)">

          <div class="upload-progress" *ngIf="(percentageChanges$ | async) as percentage">

            <mat-progress-bar class="progress-bar"
            mode="determinate"
            [value]="percentage">
            </mat-progress-bar>

            <span>{{percentage / 100 | percent}}</span>

          </div>

        </div>

</div>
