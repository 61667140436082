export const environment = {
  production: true,
  useEmulators: false,
  firebase: {
    apiKey: "AIzaSyCN-WQI-3WOSVmtBTuzlmGrrX1usq2DmZc",
    authDomain: "cryptoshareapp-a0648.firebaseapp.com",
    projectId: "cryptoshareapp-a0648",
    storageBucket: "cryptoshareapp-a0648.appspot.com",
    messagingSenderId: "1027335135493",
    appId: "1:1027335135493:web:a44caac9bedf93b503176c",
    measurementId: "G-HSEVM81J53"
  },
  api: {
    createUser: 'https://us-central1-cryptoshareapp-a0648.cloudfunctions.net/createUser'
  }
};
