<div class="social-container">

  <h3>Follow CryptoShare on other platforms</h3>

<div class="social-buttons-container">

    <a (click)="openLink('reddit')">
      <i class="social-icon fab fa-reddit"></i>
    </a>

    <a (click)="openLink('telegram')">
      <i class="social-icon fab fa-telegram"></i>
    </a>

  <a (click)="openLink('ios')">
    <i class="social-icon fab fa-app-store-ios"></i>
  </a>


</div>

</div>
