import {AirdropService} from "../services/airdrop.service";
import {AbstractControl, AsyncValidatorFn} from "@angular/forms";
import {map} from "rxjs/operators";


export function airdropTitleValidator(airdrops: AirdropService): AsyncValidatorFn{

  return (control: AbstractControl) => {
    return airdrops.findAllAirdrops()
      .pipe(
        map(airdrops => {
          const airdrop = airdrops.find(
            airdrop => airdrop.title.toLocaleLowerCase()
              .match(control.value.toLocaleLowerCase()));

          return airdrop ? {titleExists : true} : null;
        })
      )
  }

}
