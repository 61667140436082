import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AirdropService} from "../services/airdrop.service";
import {Observable} from "rxjs";
import {Airdrop} from "../model/firestore/airdrop";

@Component({
  selector: 'blueprints',
  templateUrl: './airdrop-blueprints.component.html',
  styleUrls: ['./airdrop-blueprints.component.css']
})
export class AirdropBlueprintsComponent implements OnInit {

  blueprintsAirdrops$: Observable<Airdrop[]>;

  constructor(private router: Router,
              private airdropService: AirdropService) { }

  ngOnInit(): void {
    this.reloadAirdrops();
  }

  reloadAirdrops() {
    this.blueprintsAirdrops$ = this.airdropService.loadBlueprintsAirdrop();
  }
}
