<div class="create-airdrop-panel data-form">

    <h2 class="title">Create new Airdrop</h2>

    <mat-horizontal-stepper [linear]="false"
    class="mat-elevation-z5" labelPosition="bottom">

    <mat-step [stepControl]="step1.form" errorMessage="Details page is in error">

        <ng-template matStepLabel>Details</ng-template>

        <app-airdrop-step-one #step1></app-airdrop-step-one>

        <div class="stepper-buttons">

          <button mat-raised-button color="primary" matStepperNext>
            Continue to step 2</button>

        </div>

    </mat-step>

    <mat-step [stepControl]="step2.form" errorMessage="Badges is in error">

      <ng-template matStepLabel>Badges</ng-template>

        <app-airdrop-step-two #step2></app-airdrop-step-two>

        <div class="stepper-buttons">

          <button mat-raised-button matStepperPrevious>Back to Step 1</button>

          <button mat-raised-button color="primary" matStepperNext>
            Continue to Step 3</button>

        </div>

      </mat-step>

      <mat-step [stepControl]="step3.form" errorMessage="Images is in error">

        <app-airdrop-step-three #step3 airdropId="{{airdropId}}"></app-airdrop-step-three>

        <ng-template matStepLabel>Images</ng-template>

        <div class="stepper-buttons">

          <button mat-raised-button matStepperPrevious>Back to Step 2</button>

          <button mat-raised-button color="primary" matStepperNext>
            Continue to Step 4</button>

        </div>

      </mat-step>

      <mat-step [stepControl]="step4.form" errorMessage="Steps is in error">

        <ng-template matStepLabel>Steps</ng-template>

        <app-airdrop-step-four #step4></app-airdrop-step-four>

        <div class="stepper-buttons">

          <button mat-raised-button matStepperPrevious>Back</button>

          <button mat-raised-button color="primary"
          [disabled]="!step4.form.valid ||
           step4.steps.length ===  0"
          (click)="submit(step1.form.value, step2.form.value, step3.form.value, step4.form.value)">Create Airdrop</button>

        </div>

      </mat-step>

    </mat-horizontal-stepper>

  <button mat-raised-button color="primary" class="clear-cache-button"
  (click)="clearCache()">Clear cache</button>

</div>

<div class="scrolling-space"></div>
