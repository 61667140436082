import { AirdropBlueprintsComponent } from './airdrop-blueprints/airdrop-blueprints.component';
import { CreateAirdropComponent } from './create-airdrop/create-airdrop.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo} from '@angular/fire/compat/auth-guard';
import { CreateUserComponent } from './create-user/create-user.component';
import { AboutComponent } from './about/about.component';
import { LoginComponent } from './login/login.component';
import { AirdropComponent } from './airdrop/airdrop.component';
import { AirdropResolver } from './services/airdrop.resolver';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const adminOnly = () => hasCustomClaim('admin');

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
    // canActivate: [AngularFireAuthGuard],
    // data: {
    //   authGuardPipe: redirectUnauthorizedToLogin
    // }
  },
  {
    path: 'search/:searchTerm',
    component: HomeComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin
    }
  },
  {
    path: 'create-airdrop',
    component: CreateAirdropComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: adminOnly
    }
  },
  {
    path: 'blueprints',
    component: AirdropBlueprintsComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: adminOnly
    }
  },
  {
    path: 'create-user',
    component: CreateUserComponent,
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: adminOnly
    }
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'airdrops/:airdropTitle',
    component: AirdropComponent,
    resolve: {
      airdrop: AirdropResolver
    }
    // ,
    // canActivate: [AngularFireAuthGuard],
    // data: {
    //   authGuardPipe: redirectUnauthorizedToLogin
    // }
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
