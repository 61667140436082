import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Airdrop} from "../model/firestore/airdrop";
import {AirdropService} from "../services/airdrop.service";

@Component({
  selector: 'app-edit-airdrop-steps',
  templateUrl: './edit-airdrop-steps.component.html',
  styleUrls: ['./edit-airdrop-steps.component.css']
})
export class EditAirdropStepsComponent implements OnInit {

  form: FormGroup;

  airdrop: Airdrop;

  constructor(private dialogRef: MatDialogRef<EditAirdropStepsComponent>,
              private fb: FormBuilder,
              private airdropService: AirdropService,
              @Inject(MAT_DIALOG_DATA)airdrop: Airdrop)
  {
    this.airdrop = airdrop;

    this.form = this.fb.group({
      steps: this.fb.array(
        this.airdrop.steps.map(x => this.fb.group({
          description: x.description,
          url: x.url
        }))
      )
    })
  }

  ngOnInit(): void {

  }

  get steps(){
    return this.form.controls['steps'] as FormArray;
  }

  addSteps(){
    const stepForm = this.fb.group({
      description: ['', Validators.required],
      url: ['']
    })

    this.steps.push(stepForm);
  }

  deleteStep(stepIndex) {
    this.steps.removeAt(stepIndex)
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const changes = this.form.value;

    this.airdropService.updateAirdrop(this.airdrop.id, changes)
      .subscribe(()=>{
        this.dialogRef.close(changes);
        window.location.reload();
      })
  }
}
