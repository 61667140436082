import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.css']
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  @Input() targetDate: Date;

  countdown: { days: number; hours: number; minutes: number; seconds: number };
  private countdownInterval: any;

  ngOnInit() {
    this.startCountdown();
  }

  ngOnDestroy() {
    this.stopCountdown();
  }

  private startCountdown() {
    this.countdownInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = this.targetDate.getTime() - now;

      if (distance <= 0) {
        this.stopCountdown();
        this.countdown = { days: 0, hours: 0, minutes: 0, seconds: 0 };
      } else {
        this.countdown = this.calculateCountdown(distance);
      }
    }, 1000);
  }

  private stopCountdown() {
    clearInterval(this.countdownInterval);
  }

  private calculateCountdown(distance: number) {
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));

    return { days, hours, minutes, seconds };
  }
}
