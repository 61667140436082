<div class="steps-container">

  <h2 mat-dialog-title>Edit Steps</h2>

  <mat-dialog-content  [formGroup]="form">

    <ng-container formArrayName="steps">

      <ng-container *ngFor="let stepForm of steps.controls; let i = index">

        <div class="step-form-row" [formGroup]="stepForm">

          <p>{{i+1}}.</p>

          <mat-form-field appearance="fill">

            <input matInput
                   formControlName="description"
                   placeholder="step description">

          </mat-form-field>

          <mat-form-field appearance="fill">
            <input matInput
                   formControlName="url"
                   placeholder="url if needed">

          </mat-form-field>

          <mat-icon color="warn" class="delete-btn" (click)="deleteStep(i)"
          >delete_forever</mat-icon>


        </div>

      </ng-container>

    </ng-container>

  </mat-dialog-content>

  <button mat-mini-fab (click)="addSteps()">
    <mat-icon class="add-step-btn">add</mat-icon>
  </button>

</div>



<mat-dialog-actions>

  <button mat-raised-button (click)="close()">
    Close
  </button>

  <button mat-raised-button color="primary" (click)="save()">
    Save
  </button>

</mat-dialog-actions>
