<mat-sidenav-container  fullscreen *ngIf="(user.roles$ | async) as roles">

    <mat-sidenav #start (click)="start.close()">

        <mat-nav-list>

            <a mat-list-item routerLink="/">
                <mat-icon>cake</mat-icon>
                <span>Airdrops</span>
            </a>

            <a mat-list-item routerLink="/create-user" *ngIf="roles.admin">
                <mat-icon>person_add</mat-icon>
                <span>Create user</span>
            </a>

            <a mat-list-item routerLink="about">
                <mat-icon>question_answer</mat-icon>
                <span>About</span>
              </a>

              <a mat-list-item routerLink="login" *ngIf="user.isLoggedOut$ | async">
                <mat-icon>person_add</mat-icon>
                <span>Register</span>
              </a>

              <a mat-list-item routerLink="login" *ngIf="user.isLoggedOut$ | async">
                <mat-icon>account_circle</mat-icon>
                <span>Login</span>
              </a>

              <a mat-list-item *ngIf="user.isLoggedIn$ | async" (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
                <span>Logout</span>
              </a>

        </mat-nav-list>

    </mat-sidenav>

  <mat-sidenav-content  (scroll)="onScroll($event)">

    <mat-toolbar color="primary">

      <div class="toolbar-tools">

        <button mat-icon-button (click)="start.open('mouse')">
          <mat-icon>menu</mat-icon>
        </button>

      </div>

      <div class="filler"></div>

      <ng-container>
        <h3 *ngIf="airdrops.totalLive"  class="live-title">Live airdrops: {{airdrops.totalLive}}</h3>
      </ng-container>

      <ng-container *ngIf="(user.pictureUrl$ | async) as pictureUrl">
        <img class="user-avatar" [src]="pictureUrl" alt="$">
      </ng-container>

    </mat-toolbar>

    <router-outlet></router-outlet>

    <button mat-mini-fab color="primary" (click)="scrollToTop()" class="scroll-to-top">
      <mat-icon>arrow_upward</mat-icon>
    </button>


  </mat-sidenav-content>

</mat-sidenav-container>

<meta property="og:title" content="CryptoShare - Free Crypto Airdrops" />
<meta property="og:description" content="CryptoShare is a platform for getting free cryptocurrency tokens through airdrops. Sign up now and start earning crypto today!" />
<meta property="og:image" content="https://cryptoshare.site/favicon.ico" />
<meta property="og:url" content="https://cryptoshare.site" />
<meta property="og:type" content="website" />

<meta name="twitter:card" content="summary_large_image">
<meta name="twitter:title" content="CryptoShare - Free Crypto Airdrops">
<meta name="twitter:description" content="CryptoShare is a platform for getting free cryptocurrency tokens through airdrops. Sign up now and start earning crypto today!">
<meta name="twitter:image" content="https://twitter.com/cryptoshareapp/photo">
<meta name="twitter:site" content="@cryptoshareapp">

<meta name="telegram:card" content="summary_large_image">
<meta name="telegram:title" content="CryptoShare">
<meta name="telegram:description" content="The latest crypto airdrops and giveaways will be published here">
<meta name="telegram:image" content="https://twitter.com/cryptoshareapp/photo">
<meta name="telegram:site" content="https://t.me/CryptoShareApp">

<meta name="reddit:card" content="summary_large_image">
<meta name="reddit:title" content="CryptoShareApp">
<meta name="reddit:description" content="The latest crypto airdrops and giveaways will be published here">
<meta name="reddit:image" content="https://styles.redditmedia.com/t5_4q69xh/styles/communityIcon_orbiiu3hr9b81.png?width=256&v=enabled&s=f5e1dcdf280758063d9fec53293e1ca58cdb70b6">
<meta name="reddit:site" content="https://www.reddit.com/r/CryptoShareApp/">

<meta name="ios:card" content="summary_large_image">
<meta name="ios:title" content="CryptoShare ">
<meta name="ios:description" content="Follow the latest giveaways from the crypto world. Be notified when new events occurs. Don't miss the giveaways in convenient way">
<meta name="ios:image" content="https://is3-ssl.mzstatic.com/image/thumb/Purple126/v4/a5/25/b9/a525b98d-5fd4-aeaf-473e-ce207346954c/AppIcon-0-1x_U007emarketing-0-10-0-85-220.png/434x0w.webp">
<meta name="ios:site" content="https://apps.apple.com/ua/app/cryptoshare/id1560632980">


