import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-social-links',
  templateUrl: './my-social-links.component.html',
  styleUrls: ['./my-social-links.component.css']
})
export class MySocialLinksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openLink(providerName) {

    let urlToOpen = '';

    switch (providerName){
      case 'reddit':
        urlToOpen = 'https://www.reddit.com/r/CryptoShareApp/'
        break;
      case 'telegram':
        urlToOpen = 'https://t.me/CryptoShareApp'
        break;
      case 'ios':
        urlToOpen = 'https://apps.apple.com/ua/app/cryptoshare/id1560632980'
        break;
    }

    if(urlToOpen!=''){
      window.open(urlToOpen)
    }
  }
}
