import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {from, Observable} from "rxjs";
import {concatMap, map} from "rxjs/operators";
import {convertSnaps} from './db-utils';
import firebase from "firebase/compat/app";
import {Airdrop} from "../model/firestore/airdrop";
import OrderByDirection = firebase.firestore.OrderByDirection;
import {Like} from "../model/firestore/like";
import {AngularFireStorage} from "@angular/fire/compat/storage";


@Injectable({
    providedIn: 'root'
  })
  export class AirdropService{

  totalLive: number

    constructor(
      private storage: AngularFireStorage,
      private db: AngularFirestore){
    }


  getStats(){
    this.db.collection('airdrops').doc('stats')
      .ref.get()
      .then((doc)=>{
        if (doc.exists){
          this.totalLive = doc.get('totalLive')
        }
      })
  }

  findAirdropByTitle(airdropTitle: string): Observable<Airdrop | null> {
    return this.db.collection('airdrops',
      ref => ref.where('title', '==', airdropTitle))
      .get()
      .pipe(
        map(results => {
          const  airdrops = convertSnaps<Airdrop>(results);
          return airdrops.length == 1 ? airdrops[0] : null;
        })
      );
  }



    findAirdropByUrl(airdropUrl: string): Observable<Airdrop | null> {
      return this.db.collection('airdrops',
        ref => ref.where('url', '==', airdropUrl))
        .get()
        .pipe(
          map(results => {
            const  airdrops = convertSnaps<Airdrop>(results);
            return airdrops.length == 1 ? airdrops[0] : null;
          })
        );
    }


  findLikes(airdropId:string): Observable<Like[]> {
    return this.db.collection(`airdrops/${airdropId}/likes`)
      .get()
      .pipe(
        map(results => convertSnaps<Like>(results))
      )
  }


  deleteAirdropAndLikes(airdropId:string) {
    return this.db.collection(`airdrops/${airdropId}/likes`)
      .get()
      .pipe(
        concatMap(results => {

          const likes = convertSnaps<Like>(results);

          const batch = this.db.firestore.batch();

          const airdropsRef = this.db.doc(`airdrops/${airdropId}`).ref;

          batch.delete(airdropsRef);

          for (let like of likes) {
            const likeRef =
              this.db.doc(`airdrops/${airdropId}/likes/${like.id}`).ref;

            batch.delete(likeRef);
          }

          return from(batch.commit());

        })
      );
  }

  deleteFromStorage(downloadUrl: string) {
    return this.storage.storage.refFromURL(downloadUrl).delete();
  }

    createAirdrop(newAirdrop: Partial<Airdrop>, airdropId?: string) {
      return this.db.collection('airdrops', ref => ref.orderBy('seqNo', 'desc')
        .limit(1))
        .get()
        .pipe(
          concatMap(result => {
            const airdrops = convertSnaps<Airdrop>(result);
            const lastAirdropSeqNo = airdrops[0]?.seqNo ?? 0;
            const airdrop = {
              ...newAirdrop,
              seqNo: lastAirdropSeqNo + 1
            };

            let save$: Observable<any>;

            if (airdropId){
              save$ = from(this.db.doc(`airdrops/${airdropId}`).set(airdrop));
            }else {
              save$ = from(this.db.collection('airdrops').add(airdrop));
            }

            return save$
              .pipe(
                map(res => {
                  return{
                    id: airdropId ?? res.id,
                    ...airdrop
                  };
                })
              );
          })
        );
    }

    topUpAirdrop(airdropToUpdate: Partial<Airdrop>, airdorpId: string){
      return  this.db.collection('airdrops', 
     ref => ref.orderBy('seqNo', 'desc').limit(1))
     .get()
     .pipe(

      map(result => {
        const airdrops = convertSnaps<Airdrop>(result);
        const seqNo = airdrops[0].seqNo + 1;

       this.db.doc(`airdrops/${airdorpId}`)
       .update({seqNo: seqNo})

      })
     )

    }
 

    updateAirdrop(airdropId: string, changes: Partial<Airdrop>): Observable<any> {
      return from(this.db.doc(`airdrops/${airdropId}`).update(changes));
    }


    loadAirdropsByCategory(category: string, sortOrder: OrderByDirection = 'desc'): Observable<Airdrop[]>{
      return this.db.collection('airdrops',
        ref => ref.where('categories', 'array-contains', category)
          .where('live', '==', true)
          .orderBy('seqNo', sortOrder)
      )
        .get()
        .pipe(
          map(result => convertSnaps<Airdrop>(result))
        );
    }

    loadBlueprintsAirdrop(): Observable<Airdrop[]>{
      return this.db.collection('airdrops',
        ref => ref.where('live', '==', false)
      )
        .get()
        .pipe(
          map(result => convertSnaps<Airdrop>(result))
        );
    }


  findAllAirdrops(): Observable<Airdrop[]> {
    return this.db.collection('airdrops')
      .get()
      .pipe(
        map(result => convertSnaps<Airdrop>(result))
      );
  }

  searchForAirdrop(querry: string): Observable<Airdrop[]>{
    return this.db.collection('airdrops', ref => ref.orderBy('seqNo', 'desc'))
    .get()
    .pipe(
      map(result => convertSnaps<Airdrop>(result).filter(airdrop => airdrop.title.toLowerCase().includes(querry.toLowerCase())))
    )
  }



  like(airdropId: string, userId: string){
    const likes = this.db.collection('airdrops').doc(airdropId)
      .collection('likes')
      .doc(userId).ref
        likes.get().then((doc)=>{
        if (doc.exists){
          console.log('User like exists!')
        }else {
          this.db.firestore
            .collection('airdrops')
            .doc(airdropId)
            .collection(`likes`)
            .doc(userId)
            .set({uuid: userId}).catch(err => {
            console.log(err);
          })
        }
    });
  }



   unlike(airdropId: string, userId: string): Observable<void>{
      return from(this.db.firestore
        .collection('airdrops')
        .doc(airdropId)
        .collection('likes')
        .doc(userId)
        .delete()
      )
  }










}
