import { Meta, Title } from '@angular/platform-browser';
import {Component, OnInit} from '@angular/core';
import {Airdrop} from "../model/firestore/airdrop";
import {ActivatedRoute} from "@angular/router";
import {Step} from "../model/firestore/step";
import {UserService} from "../services/user.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {EditAirdropStepsComponent} from "../edit-airdrop-steps/edit-airdrop-steps.component";
import firebase from "firebase/compat/app";
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'airdrop',
  templateUrl: './airdrop.component.html',
  styleUrls: ['./airdrop.component.css']
})
export class AirdropComponent implements OnInit{

  airdrop: Airdrop;

  steps: Step[];

  displayedColumns = ['seqNo', 'step', 'action'];

  isLoggedIn: boolean = false;

  constructor(
    private meta: Meta,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public user: UserService,
    private title: Title)
    {}

  ngOnInit(): void {
    this.user.isLoggedIn().then(loggedIn => {
      if (loggedIn) {
        this.loadAirdrop().then(() => {
          this.isLoggedIn = loggedIn;
        });
      }
    }).catch(error => {
      console.log('Error checking user authentication:', error);
    });
  }

  async loadAirdrop(){
      this.airdrop = this.route.snapshot.data['airdrop'];
      this.steps = this.airdrop.steps;
      this.title.setTitle(this.airdrop.title);

     this.meta.addTags([
    {name: 'keywords', content: this.airdrop.description},
    {name: 'robots', content: 'index, follow'},
    {name: 'author', content: this.airdrop.title},
       {name: 'description', content: this.airdrop.description},
    {charset: 'UTF-8'}
  ]);

  }

  openUrl(url: string | URL) {
    window.open(url, "_blank");
  }

  editAirdrop(airdrop: Airdrop) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "90%";

    dialogConfig.data = airdrop;

    this.dialog.open(EditAirdropStepsComponent, dialogConfig)
      .afterClosed()
      .subscribe(val => {
        if(val){
          window.location.reload();
        }
      })
  }

  checkEndDate(endDate: Timestamp){
    const end = endDate.toDate()
    const dummyDate = new Date('1970/01/01 0:00:00');
    return end > dummyDate
  }

  convertFromTimestamp(endDate: Timestamp){
    return endDate.toDate();
  }

  share(providerName) {
    let airdropUrl = this.airdrop.url;
    let airdropName = this.airdrop.title;


    let shareUrl = '';

    switch (providerName){
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer.php?u=${airdropUrl}`
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/share?url=${airdropUrl}&text=${airdropName}&hashtags=#Airdrop`
        break;
      case 'reddit':
        shareUrl = `https://reddit.com/submit?url=${airdropUrl}&title=${airdropName}`
        break;
      case 'whatsapp':
        shareUrl = `https://api.whatsapp.com/send?text=${airdropName} ${airdropUrl}`
        break;
      case 'telegram':
        shareUrl = `https://telegram.me/share/url?url=${airdropUrl}&text=${airdropName}`
        break;
    }

    if (shareUrl!=''){
      window.open(shareUrl,'desktop',
        'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
    }

  }
}

