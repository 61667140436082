import { Title } from '@angular/platform-browser';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import EmailAuthProvider = firebase.auth.EmailAuthProvider;
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import * as firebaseui from 'firebaseui';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  ui: firebaseui.auth.AuthUI;

  constructor(private afAuth: AngularFireAuth,
    private router: Router,
    private title: Title) { }


  ngOnInit(): void {
   this.title.setTitle("CryptoShareApp Login")

    this.afAuth.app.then(app => {
      const uiConfig = {
        signInOptions: [
          firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
          EmailAuthProvider.PROVIDER_ID,
          GoogleAuthProvider.PROVIDER_ID
        ],
        callbacks: {
          signInSuccessWithAuthResult: this.onLoginSuccessful.bind(this)
        }
      };

      this.ui = new firebaseui.auth.AuthUI(app.auth());

            this.ui.start("#firebaseui-auth-container", uiConfig);

            this.ui.disableAutoSignIn();

    })
  }

  ngOnDestroy(): void {
    this.ui.delete();
  }

  onLoginSuccessful(result: any) {

    console.log('Firebase UI result:', result);

    this.router.navigateByUrl("/courses");
}

}
