import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { UserService } from './services/user.service';
import {AirdropService} from "./services/airdrop.service";
import {Stats} from "./model/firestore/stats";
import {Meta, Title} from "@angular/platform-browser";
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

   title = 'Crypto share app';

   stats: Stats[];

   constructor(
    private afAuth: AngularFireAuth,
     private titleService:Title,
     private meta: Meta,
     public user: UserService,
     analytics: AngularFireAnalytics,
     public airdrops: AirdropService){

     this.user.isLoggedIn().then(loggedIn => {
       if(loggedIn){
         this.titleService.setTitle(this.title);
         analytics.logEvent('custom_event', {
           'new session': true
         }).then(result  =>{
           console.log(result);
         });
       }else{
         this.titleService.setTitle('Crypto airdrops')
       }
     })

   }

   ngOnInit(): void {
     this.user.isLoggedIn().then(loggedIn => {
       this.airdrops.getStats();
     })

     this.meta.addTags([
      {name: 'keywords', content: "New airdrop instant payment,new airdrop instant withdraw,Free Airdrop,Trust Wallet Airdrop Today,Lagit Airdrop Today,Airdrop,trust wallet instant claim Airdrop,biggest airdrop of 2022,Crypto Airdrop,Airdrop Instant Withdraw,Airdrop Crypto,Today New Airdrop,free bitcoin airdrop,new crypto airdrop 2022,free cryptocurrency token,Airdrop token,airdrop 2022,trust wallet airdrop today,new airdrop,real airdrop,new airdrop today,make money online,trust wallet new airdrop,trust wallet airdrop withdraw,crypto airdrop,airdrop,Technical Lemon,passive income,airdrop review"},
      {name: 'robots', content: "index, follow"},
      {name: 'author', content: "Crypto share app"},
      {charset: 'UTF-8'}
    ])
   }


   logout(){
    this.user.logout();
  }


  onScroll(event: { target: { scrollTop: any; }; }) {
    const scrollTop = event.target.scrollTop;
    const button = document.querySelector('.scroll-to-top') as HTMLElement;
    if (scrollTop > 200) {
      button.classList.add('show');
    } else {
      button.classList.remove('show');
    }
  }


  scrollToTop() {
    const content = document.querySelector('.mat-sidenav-content');
    if (content) {
      content.scrollTo({top:0, behavior: 'smooth'});
    }
  }


}

