<div class="content">

  <mat-card *ngFor="let coin of coins" class="coin-card airdrop-card mat-elevation-z10">
    <mat-card-title-group>
      <mat-card-title>{{coin.symbol.toLocaleUpperCase()}}</mat-card-title>
      <mat-card-subtitle>${{coin.current_price}}</mat-card-subtitle>
      <img  class="img-fluid me-4" mat-card-sm-image [src]="coin.image" alt="$">
      <mat-card-subtitle class="price-change"  [ngClass]="
                coin.price_change_percentage_24h > 0
                  ? 'text-success'
                  : 'text-danger'
              ">{{coin.price_change_percentage_24h.toLocaleString()}}%</mat-card-subtitle>
    </mat-card-title-group>
    <mat-card-content>
    </mat-card-content>
  </mat-card>
</div>

