<div class="about-component">

      <mat-card class="about-card mat-elevation-z5">
        <mat-card-title-group>
          <mat-card-title>Crypto share app</mat-card-title>
          <mat-card-subtitle>Version 0.1</mat-card-subtitle>
          <img class="mat-elevation-z5" mat-card-lg-image src="favicon.ico"  alt="">
        </mat-card-title-group>
        <mat-card-content>
          <p>
            CryptoShare offers to you the latest and tested airdrops, giveaways, and other promotions.
          </p>
          <p>
            We provide you with all necessary details and instructions to claim the reward.
          </p>
        <p>
          If you never partricipated and curious - what is airdrop?
          It's very simple - it's literally free crypto provided by exchanges or companies totally for free or for doing tasks on their platforms
          ( i.e. identity verification )
        </p>
          <p>
            In CryptoShare we collect only legal and active events, but if some project turns into a scam we will delisted it.
          </p>

        </mat-card-content>


      </mat-card>

      <div>
        <button mat-button class="mat-raised-button mat-accent" color="warn" (click)="openDonataionPage('https://donate.thedigital.gov.ua/')">
          Donate to Ukraine 🇺🇦</button>
      </div>
 

    <app-my-social-links></app-my-social-links>

    <div class="footer">
      <p>© 2023 CryptoShare.site | All rights reserved. | Created by lpwoowatpokpt</p>
      <!-- <p><a href="#">Terms of Use</a> | <a href="#">Privacy Policy</a></p> -->
    </div>

  </div>










